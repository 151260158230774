import React from "react"
import {
  LinesListFieldsFragment,
  SchedulesSearchLinkIconFragment,
  SchedulesSearchModuleFieldsFragment,
  SchedulesSearchPictogramFragment,
} from "../../../../graphql-types"
import * as styles from "./schedules-search-results-line.module.scss"
import { ReactSVG } from "react-svg"

type Line = LinesListFieldsFragment

type RenderProps = {
  line: Line
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
}

const SchedulesSearchResultsLine: React.FC<RenderProps> = ({ line, moduleConfiguration }) => {
  return (
    <section className={styles.linePanel}>
      <h5 className={styles.title}>
        {displayImage(line.pictogram, styles.pictogram)} {line.name}
      </h5>
      <ul className={styles.schedulesList}>
        {line.schedules.map(schedule => (
          <li key={schedule.id} className={styles.schedule}>
            {displayImage(moduleConfiguration.scheduleIcon, styles.linkIcon)}
            <a href={(schedule.file && schedule.file.url) || schedule.link} target="_blank">
              {schedule.label}
            </a>
          </li>
        ))}
        {line.lineMap && (
          <li key={line.id} className={styles.schedule}>
            {displayImage(moduleConfiguration.lineMapIcon, styles.linkIcon)}
            <a href={line.lineMap.url} download>
              {moduleConfiguration.lineMapLinkLabel}
            </a>
          </li>
        )}
      </ul>
    </section>
  )
}

const displayImage = (image: SchedulesSearchPictogramFragment | SchedulesSearchLinkIconFragment, className: string) => {
  if (image.format === "svg") {
    return (
      <ReactSVG
        src={image.url}
        className={className}
        beforeInjection={svg => svg.setAttribute("aria-label", image.alt || "")}
      />
    )
  }
  return <img src={image.url} alt={image.alt || ""} className={className} aria-hidden="true" />
}

export default SchedulesSearchResultsLine
