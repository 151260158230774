// extracted by mini-css-extract-plugin
export var container = "info-traffic-module--container--a33dd";
export var image = "info-traffic-module--image--30352";
export var line = "info-traffic-module--line--ce5af";
export var lineDivider = "info-traffic-module--lineDivider--00619";
export var linePicto = "info-traffic-module--linePicto--6bf3e";
export var linkNoStyle = "info-traffic-module--linkNoStyle--89c5a";
export var pictoBox = "info-traffic-module--pictoBox--146ed";
export var pictoBoxTop = "info-traffic-module--pictoBoxTop--977a1";
export var pictoBoxTraffic = "info-traffic-module--pictoBoxTraffic--e34e0";
export var svgImage = "info-traffic-module--svgImage--3ce7c";
export var title = "info-traffic-module--title--4e2e4";
export var trafficContainer = "info-traffic-module--trafficContainer--2e1b0";