import React from "react"
import { LinesListFieldsFragment, SchedulesSearchModuleFieldsFragment } from "../../../../graphql-types"
import * as styles from "./schedules-search-results.module.scss"
import SchedulesSearchResultsTransportMethod from "../results-transport-method/schedules-search-results-transport-method"

type Line = LinesListFieldsFragment
type Municipality = LinesListFieldsFragment["municipalities"][0]
type PointOfInterest = LinesListFieldsFragment["pointsOfInterest"][0]

type RenderProps = {
  lines: Line[]
  selectedLine: Line | null
  selectedMunicipality: Municipality | null
  selectedPointOfInterest: PointOfInterest | null
  moduleConfiguration: SchedulesSearchModuleFieldsFragment
}

const SchedulesSearchResults: React.FC<RenderProps> = ({
  lines,
  selectedLine,
  selectedMunicipality,
  selectedPointOfInterest,
  moduleConfiguration,
}) => {
  const filteredLines = lines.filter(filterMatchingLines(selectedLine, selectedMunicipality, selectedPointOfInterest))
  const groupedByMethod = groupByTransportMethod(filteredLines)
  if (filteredLines.length === 0) {
    return <div className={styles.noResult}>{moduleConfiguration.noResultLabel}</div>
  }
  return (
    <>
      <p className={styles.resultsTitle} id="js-schedules-search-result-label" tabIndex={-1}>
        {moduleConfiguration.resultsLabel}
      </p>
      <ul className={styles.results}>
        {Array.from(groupedByMethod.values()).map(groupLines => {
          return (
            <li key={groupLines[0].transportMethod.id} className={styles.result}>
              <SchedulesSearchResultsTransportMethod
                transportMethod={groupLines[0].transportMethod}
                lines={groupLines}
                moduleConfiguration={moduleConfiguration}
              />
            </li>
          )
        })}
      </ul>
    </>
  )
}

function filterMatchingLines(
  selectedLine: Line | null,
  selectedMunicipality: Municipality | null,
  selectedPointOfInterest: PointOfInterest | null
) {
  return line => {
    const matchLine = !selectedLine || selectedLine.id === line.id
    const matchMunicipality =
      !selectedMunicipality || line.municipalities.some(municipality => municipality.id === selectedMunicipality.id)
    const matchPointOfInterest =
      !selectedPointOfInterest ||
      line.pointsOfInterest.some(pointOfInterest => pointOfInterest.id === selectedPointOfInterest.id)

    return matchLine && matchMunicipality && matchPointOfInterest
  }
}

function groupByTransportMethod(lines: Line[]) {
  return lines.reduce((linesByTransportMethod, line) => {
    const existingEntries = linesByTransportMethod.get(line.transportMethod.id) || []
    const newEntries = existingEntries.concat(line)
    linesByTransportMethod.set(line.transportMethod.id, newEntries)
    return linesByTransportMethod
  }, new Map<string, Line[]>())
}

export default SchedulesSearchResults
